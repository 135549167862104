import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TransLink from 'components/TransLink';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

import classes from './VacancyItem.scss';

function VacancyItem({ title, to, children: childrenProp }) {
  const [hover, setHover] = useState(false);
  const children = childrenProp.length ? childrenProp : [childrenProp];

  const handleEnter = () => setHover(true);
  const handleLeave = () => setHover(false);

  return (
    <TransLink
      className={classes.root}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      to={to}
    >
      <div className={classes.content}>
        <Heading color="primary-light">{title}</Heading>
        <Text>{children}</Text>
      </div>

      <Button
        color="tertiary"
        icon="arrow-right"
        to={to}
        hover={hover}
        outline
      />
    </TransLink>
  );
}

VacancyItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default VacancyItem;
