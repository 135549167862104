import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import vacaturesImage from 'assets/images/pages/vacatures/1.jpg';

import Title from 'blocks/Title';
import Content from 'blocks/Content';
import Grid from 'blocks/Grid';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import VacancyItem from 'components/VacancyItem';
import Cta from 'components/Cta';

const Vacatures = ({ data }) => {
  const items = data.allVacancies.nodes;

  return (
    <Layout>
      <Seo image={{ src: vacaturesImage }} />

      <Title
        image={{ src: vacaturesImage }}
        alt="Klaar om ons team te komen versterken?"
        subtitle="Openstaande vacatures"
        title="Klaar om ons team te komen versterken?"
        spacing
      >
        Bij Batao is onze focus het maken van hoogwaardige digitale producten.
        Dat doen we met een klein en hecht team.
      </Title>

      <Section color="secondary-pastel" position="left">
        <Content>
          <Heading size="xl" as="h2">
            Onze openstaande vacatures
          </Heading>
        </Content>

        <Content wide>
          <Grid width={1}>
            {items.map((item) => (
              <VacancyItem
                key={item._id}
                title={item.title}
                to={`/vacatures/${item.slug}`}
              >
                {item.description}
              </VacancyItem>
            ))}
          </Grid>
        </Content>
      </Section>

      <Section>
        <Content wide>
          <Cta>
            <Content>
              <Heading size="xl" spacing="xs">
                Staat jouw droombaan er niet tussen?
              </Heading>

              <Text color="gray-dark" spacing="lg">
                Mooi! Dan komen we graag snel met je in contact. Je kan jouw CV
                (met eventueel een leuke brief) sturen aan Remco de Jong via
                clan@batao.io.
              </Text>

              <Button color="tertiary" to="/contact">
                Verstuur open sollicitatie
              </Button>
            </Content>
          </Cta>
        </Content>
      </Section>
    </Layout>
  );
};

Vacatures.propTypes = {
  data: PropTypes.shape({
    allVacancies: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query vacancies {
    allVacancies {
      nodes {
        _id
        slug
        title
        description
      }
    }
  }
`;

export default Vacatures;
